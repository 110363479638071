var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex",staticStyle:{"gap":"0.5rem"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-0",attrs:{"disabled":_vm.disabled,"elevation":"0"},on:{"click":_vm.focusFilter}},'v-btn',attrs,false),on),[_c('img',{staticClass:"rounded mr-1",attrs:{"src":_vm.selectedDdi.image,"height":"25px","width":"25px","loading":"lazy","alt":`Bandeira de ${_vm.selectedDdi.name}`}}),_vm._v(" +"+_vm._s(_vm.selectedDdi.ddi)+" ")])]}}])},[_c('v-card',{staticClass:"pa-1"},[_c('v-text-field',{ref:"filter",attrs:{"dense":"","outlined":"","hide-details":"","label":"Pesquisar"},on:{"click":function($event){$event.stopPropagation();},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.selectFirst.apply(null, arguments)}},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('v-list',{staticStyle:{"height":"200px","overflow-y":"auto"}},_vm._l((_vm.ddiFilteredTable),function(item,index){return _c('v-list-item',{key:index,staticClass:"rounded",class:{ primary: item.ddi === _vm.selectedDdi.ddi },on:{"click":function($event){return _vm.select(item)}}},[_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',{staticClass:"d-flex align-center"},[_c('img',{staticClass:"rounded mr-1",attrs:{"src":item.image,"height":"25px","width":"25px","loading":"lazy","alt":`Bandeira de ${item.name}`}}),_vm._v(" +"+_vm._s(item.ddi)+" "+_vm._s(item.name)+" ")])],1)],1)}),1)],1)],1),_c('v-text-field-simplemask',{ref:"wa",staticClass:"flex-grow-1",attrs:{"label":_vm.label,"properties":{
      prefix: '',
      suffix: '',
      outlined: _vm.dense,
      disabled: _vm.disabled,
      dense: _vm.dense,
      type: 'tel',
      placeholder: _vm.computedMask,
      rules: [
        (v) => !!v || 'WhatsApp é obrigatório',
        (v) => _vm.selectedDdi.pattern.test(v) || 'WhatsApp inválido',
      ],
    },"options":{
      inputMask: _vm.computedMask,
      outputMask: _vm.computedMask,
      empty: '',
      applyAfter: false,
      alphanumeric: false,
      lowerCase: false,
    }},model:{value:(_vm.phoneValue),callback:function ($$v) {_vm.phoneValue=$$v},expression:"phoneValue"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }